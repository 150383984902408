import axios from 'axios'

import { errorConverter } from './converters/error'

const defaultHeaders = {
  'Content-Type': 'application/json',
}

export const httpDefaultConfig = { headers: {} }

class HttpClient {
  api
  externalRequest

  constructor(config = httpDefaultConfig, externalRequest = false) {
    config.headers = { ...config.headers, ...defaultHeaders }
    /* this is to prevent the use of absolute urls due to security reasons.
     It should be true only for external requests
     See more here https://github.com/advisories/GHSA-jr5f-v2jv-69x6
   */
    config.allowAbsoluteUrls = config.allowAbsoluteUrls ?? false
    this.api = axios.create(config)
    this.externalRequest = externalRequest
  }

  addRequestInterceptor(interceptors) {
    this.api.interceptors.request.use(interceptors)
  }

  addResponseInterceptor(success, error) {
    this.api.interceptors.response.use(success, error)
  }

  addErrorInterceptor(error) {
    this.api.interceptors.response.use((res) => res, error)
  }

  addDefaultHeader(headerName, headerValue) {
    this.api.defaults.headers.common[headerName] = headerValue
  }

  convertPromiseResponse(promise) {
    return new Promise((resolve, reject) => {
      promise
        .then((response) => {
          resolve(
            this.externalRequest
              ? {
                  success: true,
                  errors: [],
                  data: response.data,
                }
              : response.data
          )
        })
        .catch((error) => reject(errorConverter(error)))
    })
  }

  get({ url = '', data, options = {} }) {
    return this.convertPromiseResponse(
      this.api.get(url, {
        params: data ?? {},
        ...options,
      })
    )
  }

  post({ url = '', data, options = {} }) {
    return this.convertPromiseResponse(this.api.post(url, data, options))
  }
  patch({ url = '', data, options = {} }) {
    return this.convertPromiseResponse(this.api.patch(url, data, options))
  }

  put({ url = '', data, options = {} }) {
    return this.convertPromiseResponse(this.api.put(url, data, options))
  }

  delete({ url = '', data, options = {} }) {
    return this.convertPromiseResponse(
      this.api.delete(url, { data, ...options })
    )
  }
}
export default HttpClient
