import HttpClient, { httpDefaultConfig } from './config/http/HttpClient'
import {
  authorizationRequestInterceptor,
  authorizationResponseSuccessInterceptor,
  authorizationResponseErrorInterceptor,
} from './config/interceptors/authorization/authorization.interceptor'
import { errorInterceptor } from './config/interceptors/error/error.interceptor'
import LoadingInterceptor from './config/interceptors/loading/loading.interceptor'
import { internalURLRequestInterceptor } from './config/interceptors/url/url.interceptor'
const SITES_API_URLS = {
  development: 'https://api-sites-loja.develop.juntossomosmaisi.com.br',
  local: 'https://api-sites-loja.develop.juntossomosmaisi.com.br',
  staging: 'https://api-sites-loja.homolog.juntossomosmaisi.com.br',
  production: 'https://api-sites-loja.juntossomosmais.com.br',
}

const createInternalApi = (baseURL) => {
  const internalAPI = new HttpClient({
    baseURL: baseURL,
    withCredentials: true,
  })

  internalAPI.addRequestInterceptor(authorizationRequestInterceptor)
  internalAPI.addResponseInterceptor(
    authorizationResponseSuccessInterceptor,
    authorizationResponseErrorInterceptor
  )
  internalAPI.addRequestInterceptor(LoadingInterceptor.request)
  internalAPI.addResponseInterceptor(
    LoadingInterceptor.response,
    LoadingInterceptor.error
  )
  internalAPI.addErrorInterceptor(errorInterceptor)
  internalAPI.addRequestInterceptor(internalURLRequestInterceptor)
  return internalAPI
}

export const sitesApi = createInternalApi(
  SITES_API_URLS[process.env.VUE_APP_ENV]
)

export const external = new HttpClient(
  { ...httpDefaultConfig, allowAbsoluteUrls: true },
  true
)
